import React from 'react';
import logo from '../../assets/logo/lfz_logo_flag.svg';
import { Link } from 'react-router-dom';

function Success() {
    return (
        <div className="flex justify-center items-center bg-transparent min-h-[100lvh] w-full">
            <div className="flex flex-col items-center xl:gap-[100px] gap-[80px] w-full">
                <img
                    src={logo}
                    alt="Lost In Melo"
                    className="xl:w-[300px] w-[232px]"
                />

                <p className='xl:max-w-[360px] text-center font-bold'>
                    Ta réservation a bien été enregistrée ! <br />
                    Le lieu te sera communiqué la veille du 27 😉 !
                </p>

                <Link to={"/"} type="submit"
                    className="flex justify-center items-center py-2.5 xl:mt-[15px] mt-2.5 xl:h-[60px] outline-none bg-black text-wheat xl:text-[32px] text-[28px] px-10 rounded-full text-center font-bold">
                    OK</Link>
            </div>
        </div>
    )
}

export default Success