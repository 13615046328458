import './home.css';
import React, { useState } from 'react';
import poster from '../../assets/images/LaFriendzone-Birthday-Party.png';
import RightSidenav from '../Sidenav/Sidenav';
import dawn from '../../assets/logo/Dawn.svg';

function Home() {

    const [isSidenavOpen, setIsSidenavOpen] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedCoverUrl, setSelectedCoverUrl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    function toggleSidenav(sessionId, coverUrl, date, month) {
        setSelectedSession(sessionId);
        setSelectedCoverUrl(coverUrl);
        setSelectedDate(date);
        setSelectedMonth(month);
        setIsSidenavOpen(!isSidenavOpen);
    }

    function handleReservation(session, coverUrl, date, month) {
        // Mise à jour de la session et ouverture du side-nav
        toggleSidenav(session, coverUrl, date, month);
        console.log(date + '+' + month)
    }

    const dayParties = [
        {
            session: "BIRTHDAY BASH",
            date: "27",
            month: "OCTOBRE",
            startTime: "15",
            endTime: "23",
            coverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/SS01-SQ.jpg?alt=media&token=e680f666-1d2c-45e3-a842-698efa68c157",
            status: 1
        },
    ]

    return (
        <body className="flex flex-col items-center justify-center bg-transparent h-full">

            <div className="flex flex-col items-center justify-center lg:px-[30px] lg:py-5 lg:m-0 p-5 w-full lg:h-[100lvh]">

                <div className="lg:grid lg:grid-cols-2 grid-cols-1 rounded-[20px] w-full h-full">

                    <div
                        className="flex items-center justify-center bg-wheat border-bloody border-2 w-full h-full lg:rounded-l-[20px] lg:rounded-tr-none rounded-t-[20px] lg:px-32 lg:py-0 py-[40px]"
                    >
                        <img className="max-w-[350px] lg:max-w-[500px]" src={poster} alt="" />
                    </div>

                    <div
                        className="flex flex-col items-center bg-transparent border-bloody border-2 w-full h-full lg:rounded-r-[20px] lg:rounded-b-none rounded-b-[20px] lg:px-8 px-[20px] lg:py-[50px] py-[30px] gap-10">


                        <div className="flex flex-col w-full h-full lg:gap-5 gap-[15px] lg:pb-0 pb-10" id="dayparties">

                            {dayParties.map((session, index) => (

                                <div key={index} className="flex flex-col justify-center pt-5 gap-[60px] w-full h-full">

                                    <div className="flex flex-col justify-center items-center text-center text-black w-full">
                                        <p className="font-extrabold text-bloody lg:text-[54px] text-[28px]">CELEBRONS <br /> LE MOUVEMENT</p>
                                    </div>

                                    <div className="flex flex-col gap-[50px] lg:items-center items-center justify-center lg:w-full">

                                        <div className="flex justify-between items-center w-full">
                                            <img className="max-w-[30px] lg:max-w-[50px]" src={dawn} alt="" />

                                            {
                                                session.status === 2 ? (
                                                    <button
                                                        id="openModal"
                                                        className="text-black bg-transparent lg:text-[20px] text-[18px] font-bold h-[36px] p-2.5 rounded-full reserve lg:w-auto w-fit cursor-not-allowed"
                                                        disabled
                                                    >
                                                        OUTDATED
                                                    </button>
                                                ) : session.status === 1 ? (
                                                    <button
                                                    disabled
                                                        id="openModal"
                                                        
                                                        className="text-black bg-transparent border-2 border-black g:text-[24px] text-[12px] font-bold lg:px-[60px] lg:py-2.5 px-[35px] py-[15px] rounded-full reserve cursor-not-allowed"
                                                    >
                                                        SOLD OUT
                                                    </button>
                                                ) : session.status === 0 && (
                                                    <button
                                                        id="openModal"
                                                        className="text-black bg-transparent lg:text-[20px] text-[18px] font-bold h-[36px] p-2.5 rounded-full reserve lg:w-auto w-fit cursor-not-allowed"
                                                        disabled
                                                    >
                                                        NOT AVAILABLE
                                                    </button>
                                                )
                                            }
                                            <img className="max-w-[30px] lg:max-w-[50px]" src={dawn} alt="" />

                                        </div>

                                        <p className='max-w-[250px] xl:text-[12px] text-[10px] text-center'>4 ANS C'EST PAS PETIT, ON VA FAIRE CA BIEN (EVEN BETTER THAN USUAL). ET N'OUBLIE PAS LE CADEAU ! BISOUS 😘</p>
                                    </div>


                                </div>

                            ))}

                        </div>

                    </div>

                </div>

            </div>

            <RightSidenav
                isOpen={isSidenavOpen}
                closeSidenav={() => setIsSidenavOpen(false)}
                session={selectedSession}
                coverUrl={selectedCoverUrl}
                date={selectedDate}
                month={selectedMonth}
                toggleSidenav={toggleSidenav}
            />

        </body>

    )
}

export default Home