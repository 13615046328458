import React, { useState } from 'react';
import logo from '../../assets/logo/lfz_logo_flag.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from '../../firebase';


function Atalakou() {

    const navigate = useNavigate();
    const [atalakou, setAtalakou] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const { firstname, email, phoneNumber } = location.state || {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            // Ajouter les données dans Firestore
            await addDoc(collection(db, "atalakou"), {
                text: atalakou,
                firstname: firstname.toLowerCase(),
                email: email,
                phoneNumber: phoneNumber,
                sentAt: Timestamp.now(),
            });

            // Réinitialiser les champs après le succès
            setAtalakou('');

            navigate('/success');

            return true; // Indique que l'opération a réussi
        } catch (error) {
            console.error("Erreur lors de l'envoi des données vers Firestore : ", error);
            return false; // Indique que l'opération a échoué
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="flex justify-center bg-transparent min-h-[100vh] xl:py-[81px] py-[90px]">

            <div className="flex flex-col items-center xl:gap-10 gap-5">
                <img
                    src={logo}
                    alt="LaFriendzone"
                    className="xl:w-[300px] w-[232px]"
                />

                <p className='max-w-[300px] text-center font-bold xl:text-[14px] text-[12px] mt-5'>
                    AVANT DE PARTIR, FAIS-NOUS UN PETIT ATALAKOU ( LAFRIENDZONE, C'EST QUOI POUR TOI 🙃 ? / TON MEILLEUR SOUVENIR AVEC NOUS 😏)
                </p>

                <form onSubmit={handleSubmit} className="flex flex-col gap-10 w-full">
                    
                    <textarea className="flex justify-center text-wheat font-light lg:text-[20px] text-[16px] xl:w-[500px] w-full xl:h-[280px] h-[280px] bg-black rounded-2xl lg:px-[45px] lg:py-5 px-[30px] py-5 focus:outline-none"
                        placeholder="Ton Atalakou ( oui, on a laissé BEAUCOUP d'espace pour que tu écrives BEAUCOUP alors ne nous fais pas regretter 🚶🏾‍♂️ )"
                        type="text"
                        name="atalakou"
                        value={atalakou} onChange={(e) => setAtalakou(e.target.value)}
                        required>
                    </textarea>

                    <div className="flex justify-end items-center">
                        <button
                            className="flex justify-center text-wheat font-bold lg:text-[24px] text-[14px] bg-black rounded-2xl lg:w-[200px] w-[100px] lg:px-[45px] lg:py-5 px-[30px] py-5 select-none"
                            type="submit">
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                "Envoyer"
                            )}
                        </button>
                    </div>

                </form>

                <Link to={"/success"} className='max-w-[300px] text-center text-bloody cursor-pointer xl:text-[14px] text-[12px] mt-10'>CLICKE ICI SI TU NE VEUX RIEN PARTAGER AVEC NOUS PARCE QUE TU ES AIGRI (E) 😝 !</Link>

            </div>
        </div >
    )
}

export default Atalakou