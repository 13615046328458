// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAm-2jP-CtOeshB4bWs2V3p99OIs4Glc-I",
  authDomain: "lafriendzone-38e17.firebaseapp.com",
  projectId: "lafriendzone-38e17",
  storageBucket: "lafriendzone-38e17.appspot.com",
  messagingSenderId: "1093587796918",
  appId: "1:1093587796918:web:0ee71584ed13b19217f717",
  measurementId: "G-QXTM941N5T"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };