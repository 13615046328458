/* eslint-disable react-hooks/rules-of-hooks */
import './sidenav.css';
import { addDoc, collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import React, { useRef, useState } from 'react';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

function RightSidenav({ isOpen, toggleSidenav, session, coverUrl, date, month }) {
    if (!isOpen) return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    const [firstname, setFirstName] = useState('');
    const [email, setEmail] = useState('');


    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');

    const intlTelInputRef = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const handlePhoneChange = (status, value, countryData, number, id) => {
        setPhoneNumber(countryData.dialCode + value);
    };

    const checkIfPhoneNumberExists = async (phoneNumber) => {
        const phoneQuery = query(collection(db, session), where("phoneNumber", "==", phoneNumber));
        const phoneSnapshot = await getDocs(phoneQuery);
        return !phoneSnapshot.empty;
    };

    const checkIfEmailExists = async (email) => {
        const emailQuery = query(collection(db, session), where("email", "==", email));
        const emailSnapshot = await getDocs(emailQuery);
        return !emailSnapshot.empty;
    };

    const form = useRef();

    const sendEmail = () => {
        const serviceID = 'default_service';
        const templateID = 'template_tnz2ig3';
        const publicKEY = 'WMY8K57nafuvt3VUm';

        return emailjs
            .sendForm(serviceID, templateID, form.current, {
                publicKey: publicKEY,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    throw new Error('Échec de l\'envoi de l\'email');
                }
            );
    };

    const handleSubmit = async () => {
        // Vérification que tous les champs sont remplis
        if (!firstname || !email || !phoneNumber) {
            if (!email) setEmailError('L\'adresse email est requise.');
            if (!phoneNumber) setPhoneNumberError('Le numéro de téléphone est requis.');
            return; // Arrête l'exécution de la fonction si un champ est manquant
        }
    
        setIsLoading(true);
        setEmailError(''); // Réinitialiser le message d'erreur email
    
        try {
            const phoneNumberExists = await checkIfPhoneNumberExists(phoneNumber);
            const emailExists = await checkIfEmailExists(email);
    
            if (phoneNumberExists) {
                setPhoneNumberError('Ce numéro a déjà effectué une réservation pour la ' + session + ' !');
                return false; // Sortie anticipée si l'email existe déjà
            } else {
                setPhoneNumberError('');
            }
    
            if (emailExists) {
                setEmailError('Cette adresse email a déjà effectué une réservation pour la ' + session + ' !');
                return false; // Sortie anticipée si l'email existe déjà
            }
    
            // Ajouter les données dans Firestore
            await addDoc(collection(db, session), {
                firstname: firstname.toLowerCase(),
                email: email,
                phoneNumber: phoneNumber,
                sentAt: Timestamp.now(),
            });
    
            // Réinitialiser les champs après le succès
            setFirstName('');
            setEmail('');
            setPhoneNumber('');
    
            toggleSidenav();
            navigate('/atalakou', {
                state: {
                  firstname: firstname,
                  email: email,
                  phoneNumber: phoneNumber,
                },
              });
    
            return true; // Indique que l'opération a réussi
        } catch (error) {
            setEmailError('Une erreur est survenue lors de l\'inscription. Veuillez réessayer.');
            console.error("Erreur lors de l'envoi des données vers Firestore : ", error);
            return false; // Indique que l'opération a échoué
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Exécute handleSubmit et vérifie s'il a réussi
        const submitSuccess = await handleSubmit();

        // if (submitSuccess) {
        //     // Appel de la fonction d'envoi de l'email seulement si handleSubmit a réussi
        //     await sendEmail();
        // }
    };

    return (
        <div className="relative">
            {/* Le sidenav qui sort du côté droit */}
            <div
                className={`fixed top-0 right-0 h-full lg:w-[600px] w-full overflow-y-scroll side bg-wheat text-black transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
                    } transition-transform duration-300 ease-in-out z-40`}
            >
                <div className="flex flex-col gap-10 p-[40px]">

                    <div class="flex items-start justify-end font-bold">
                        <div onClick={toggleSidenav} className='flex justify-center items-center rounded-full text-[24px] bg-black text-wheat p-1 h-10 w-10 cursor-pointer'>&times;</div>
                    </div>

                    <p className='xl:text-[24px] text-[20px] font-bold text-bloody ml-2 border-b-2 border-black w-fit' >LaFriendzone - {session}</p>

                    <div class="flex flex-col items-start justify-start">
                        <p className="xl:text-[20px] ml-2 text-black">Veuillez renseigner vos informations pour la réservation.</p>
                        <br />
                        <p className='xl:text-[20px] ml-2 text-black'>Les places sont limitées, alors assure-toi d'être là ! L'entrée est à <strong>4.000 XOF</strong> (Payable à l'entrée du lieu), + un ticket conso offert. 🎫🍹</p>
                    </div>

                    <form onSubmit={handleFormSubmit} ref={form} className="flex flex-col gap-10 mt-5">

                        <input type="hidden" id="session" name="session" value={session} />

                        <input type="hidden" id="coverUrl" name="coverUrl" value={coverUrl} />

                        <input type="hidden" id="date" name="date" value={date} />

                        <input type="hidden" id="month" name="month" value={month} />

                        <input
                            className="flex justify-center text-wheat font-light lg:text-[20px] text-[16px] bg-black rounded-full lg:px-[45px] lg:py-5 px-[30px] py-5 focus:outline-none"
                            placeholder="Ton prénom"
                            type="text"
                            name="firstname"
                            value={firstname} onChange={(e) => setFirstName(e.target.value)}
                            required />

                        <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            defaultCountry="tg"
                            preferredCountries={['tg', 'gh', 'bj', 'ci', 'fr', 'gb', 'us']}
                            formatOnInit={true}
                            onPhoneNumberChange={handlePhoneChange}
                            separateDialCode={true}
                            geoIpLookup={false}
                            ref={intlTelInputRef}
                        />
                        {phoneNumberError && <p className="text-red-500">{phoneNumberError}</p>}

                        <input
                            className="flex justify-center text-wheat font-light lg:text-[20px] text-[16px] bg-black rounded-full lg:px-[45px] lg:py-5 px-[30px] py-5 focus:outline-none"
                            placeholder="Ton email"
                            type="email"
                            name="email"
                            value={email} onChange={(e) => setEmail(e.target.value)}
                            required />

                        {emailError && <p className="text-black font-light lg:text-[20px] text-[14px] text-left ml-2 -mt-[20px]">{emailError}</p>}


                        <div className="flex justify-end items-center">
                            <button
                                className="flex justify-center text-wheat font-bold lg:text-[20px] text-[16px] bg-black rounded-full lg:w-[200px] w-[100px] lg:px-[45px] lg:py-5 px-[30px] py-5 select-none"
                                type="submit">
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : (
                                    "Reserver"
                                )}
                            </button>
                        </div>

                    </form>

                </div>
            </div>

            {/* Overlay pour fermer quand on clique en dehors */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black opacity-50 z-30"
                    onClick={toggleSidenav}
                ></div>
            )}
        </div>
    );
};

export default RightSidenav;
